export default [
    { title: "Disable async interceptor for calls to CM connector", key: "disable-async", anon: true },
    { title: "Test Service / Sub Tab 2", key: "Test.TestService/subTab2" },
    { title: "Async", key: "enable-async", anon: true },
    { title: "Batching", key: "enable-batching", anon: true },
    { title: "Always show relationship lines", key: "relationships-always-on" },
    { title: "Menu categories always open", key: "menuCategoriesOpen" },
    { title: "Demo Mode", key: "demo-mode", anon: true },
    { title: "Old CVO Resources", key: "oldCvoResources" }
];
