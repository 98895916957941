import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import querystring from "query-string";
import { getTenancy } from "../clients"

const noListener = () => false;
export default ({ iframeUrl, messagePrefix = "SERVICE", listener = noListener, shouldListen = true, isThirdParty, trackKey, setWizardOpen }) => {
    const iframeRef = useRef(null);
    const isReadyRef = useRef(false)
    const [readyState, setReadyState] = useState(null);
    const [supportV2, setSupportV2] = useState(false);
    const initializationData = useRef({});
    const iframeOrigin = iframeUrl && new URL(iframeUrl, iframeUrl.startsWith("http") ? undefined : window.location.origin).origin;
    const history = useHistory();

    const apiResponses = useSelector(state => state.occmProxy?.responses);
    const { idTokenPayload: userMetadata, accessToken, thirdPartyToken } = useSelector(state => state.auth);
    const { selectedAgentId: connectorId, selectedAccountId: accountId, selectedWorkspaceId: workspaceId, isDemo: isDemoMode, selectedOrgId: organizationId, selectedScopeId: scopeId } = useSelector(state => state.tenancy);
    const location = useLocation();
    const dispatch = useDispatch();

    let selection = undefined;

    if (location?.state?.selection) {
        if (location.state.selection?.workingEnvironments) {
            selection = {
                workingEnvironments: location.state.selection.workingEnvironments
            };
        } else if (location.state.selection?.workingEnvironment) {
            selection = {
                workingEnvironment: location.state.selection.workingEnvironment
            };
        }
    }

    initializationData.current = {
        userMetadata: window.btoa(JSON.stringify(userMetadata)),
        ...location.state,
        accessToken: isThirdParty ? thirdPartyToken : accessToken,
        accountId: accountId === "no-project" ? undefined : accountId,
        workspaceId,
        organizationId: organizationId || undefined,
        scopeId: scopeId || undefined,
        connectorId: connectorId || undefined,
        agentId: location.pathname?.startsWith("/governance") ? connectorId?.substring(0, connectorId.length - 7) : (connectorId || undefined),
        isDemoMode: isDemoMode || false,
        fromService: location?.state?.fromService || "direct",
        fromAction: _.get(location, "state.action"),
        selection: selection ? window.btoa(JSON.stringify(selection)) : undefined,
        pathname: location.pathname,
        hash: location.hash,
        domain: window.location.host
    };

    const sendMessage = useCallback(({ type, payload }) => {
        if (iframeRef.current && isReadyRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: `${messagePrefix}:${type}`,
                payload
            }, iframeOrigin);
        }
    }, [iframeRef, iframeOrigin, messagePrefix]);

    useEffect(() => {
        if (supportV2) {
            sendMessage({
                type: "ON-READY",
                payload: initializationData.current
            })
        }
    }, [sendMessage, supportV2])

    useEffect(() => {
        if (shouldListen) {
            const receiveMessage = (event) => {
                if (!iframeRef.current || event.origin !== iframeOrigin || event.source !== iframeRef.current.contentWindow) {
                    return;
                } else {
                    if (_.isObject(event.data)) {
                        const { type, payload } = event.data;

                        switch (type?.substring(messagePrefix.length + 1)) {
                            case "SERVICE:READY":
                            case "READY": {
                                setSupportV2(payload === 'iframev2');
                                setReadyState(uuidv4());
                                isReadyRef.current = true;
                                if (location.state?.onReadyMessage) {
                                    sendMessage(location.state.onReadyMessage)
                                }
                                return;
                            }
                            case "SERVICE:NAVIGATE":
                            case "NAVIGATE": {

                                if (payload.hash) {
                                    history.replace({
                                        ...history.location,
                                        hash: payload.hash
                                    })
                                } else {
                                    history.push(payload.pathname, {
                                        ...payload.state,
                                        fromService: "-",
                                        action: payload.action,
                                        selection: payload?.selection?.extraParams
                                    })
                                }
                                return;
                            }
                            case "OCCM-PROXY": {
                                dispatch({
                                    type: 'SERVICE:OCCM-PROXY', payload
                                });
                                return;
                            }
                            case "SERVICE:ADD-NSS": 
                            case "ADD-NSS": {
                                dispatch({
                                    type: "SUPPORT:ADD-NSS-OPEN-POPOVER",
                                    track: `from:${trackKey}`,
                                    payload
                                })
                                return;
                            }
                            case "SERVICE:NOTIFICATION": {
                                dispatch({
                                    type: payload.type,
                                    payload: {
                                        message: payload.message,
                                        exception: payload.exception,
                                        group: payload.group
                                    }
                                });
                            }
                            case "OPEN-WIZARD": {
                                setWizardOpen(true);
                                return;
                            }
                            case "CLOSE-WIZARD": {
                                setWizardOpen(false);
                                return;
                            }
                            default: {
                                const matched = listener(event.data);
                                if (!matched) {
                                    console.log(`Parent APP received unknown message type - ${type}`);
                                }
                                return;
                            }
                        }
                    }
                }
            };

            window.addEventListener("message", receiveMessage, false);

            return () => {
                window.removeEventListener("message", receiveMessage)
            }
        }
    }, [iframeOrigin, messagePrefix, history, listener, shouldListen, dispatch, trackKey, setWizardOpen, location.state?.onReadyMessage, sendMessage]);

    useEffect(() => {
        sendMessage({
            type: "CONNECTOR-CHANGE",
            payload: {
                connectorId
            }
        })
    }, [sendMessage, connectorId]);

    useEffect(() => {
        sendMessage({
            type: "WORKSPACE-CHANGE",
            payload: {
                workspaceId,
                scopeId
            }
        })
    }, [sendMessage, workspaceId]);

    useEffect(() => {
        sendMessage({
            type: "TOKEN-UPDATE",
            payload: {
                accessToken,
                userMetadata
            }
        })
    }, [sendMessage, accessToken, userMetadata]);

    useEffect(() => {
        if (apiResponses.length > 0) {
            const messageGroups = _.groupBy(apiResponses, "type");

            if (messageGroups["OCCM-PROXY-RESPONSE"]?.length > 0) {
                sendMessage({
                    type: "OCCM-PROXY-RESPONSE",
                    payload: {apiResponses: _.map(messageGroups["OCCM-PROXY-RESPONSE"], "payload")}
                })
            }

            if (messageGroups["NSS-ADDED"]?.length > 0) {
                sendMessage({
                    type: "NSS-ADDED"
                })
            }

            if (messageGroups["ADD-NSS-FAILED"]?.length > 0) {
                sendMessage({
                    type: "ADD-NSS-FAILED"
                })
            }

            dispatch({
                type: 'OCCM-PROXY:RESPONSES-HANDLED', payload: apiResponses
            });
        }

    }, [sendMessage, apiResponses, dispatch]);

    const { pathname, hash, search } = location
    useEffect(() => {
        sendMessage({
            type: "LOCATION-CHANGE",
            payload: { pathname, hash, search }
        })
    }, [sendMessage, pathname, hash, search]);

    const src = useMemo(() => {
        if (!shouldListen) {
            return null
        } else {
            const search = querystring.stringify(initializationData.current);
            return iframeUrl + (initializationData.current?.pathSuffix || '') + `?${search}`;
        }
    }, [iframeUrl, shouldListen]);

    return useMemo(() => {
        return { isInitialized: !!readyState, iframeRef, src }
    }, [readyState, src])
};
