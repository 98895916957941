import React, { useEffect, useState, useMemo, Suspense } from "react";
import externals from "utils/externals";
import useCurrentService from "utils/useCurrentService";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Redirect, Switch, Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { MainLoader, TabHeader, TabLinks } from "@netapp/bxp-design-system-react";
import _ from "lodash";
import RenderIframe from "./components/iframe/RenderIframe"
import RenderMfe from "./components/mfe/RenderMfe"
import DefaultRoute from "modules/app/components/DefaultRoute";

const ParentService = React.memo(({ currentService }) => {
    const [isWizardOpen, setWizardOpen] = useState(false);
    const activeFeatures = useSelector(state => state.features.active);
    const mfeServices = useSelector(state => state.app.mfeServices);

    const subServicesByTo = _.keyBy(currentService.service.subServices, "to");
    const stageIntegrationsByPath = _.keyBy(currentService.uiIntegration?.stageIntegrations, "path");
    const featuresById = _.keyBy(currentService.uiIntegration?.features, "id");

    const subTabs = _.filter(currentService.uiIntegration?.subTabs, subTab => {
        if(subServicesByTo[subTab.to]){ //legacy way of checking feature status
            return activeFeatures[`${currentService.key}.${subServicesByTo[subTab.to].key}`];
        }

        const stageIntegration = stageIntegrationsByPath[subTab.to];
        return !!stageIntegration && (subTab.featureId ? activeFeatures[subTab.featureId] : true);
    }).map(subTab => {
        const legacySubService = subServicesByTo[subTab.to];
        const legacyIframeKey = legacySubService?.iframeKey || legacySubService?.key;
        const endpointId =  stageIntegrationsByPath[subTab.to]?.endpointId;
        const isMfeApp = !_.isUndefined(externals.serviceMfes[endpointId]);

        if (isMfeApp) { 
            return {
                to: subTab.to,
                label: subTab.label,
                legacySubService,
                appKey: endpointId,
                isMfeApp,
                featureTargets: subTab.featureId ? featuresById[subTab.featureId]?.targets : undefined,
                scope: stageIntegrationsByPath[subTab.to]?.scope
            }
        } else {
            return {
                to: subTab.to,
                label: subTab.label,
                legacySubService,
                version: stageIntegrationsByPath[subTab.to]?.version || "childIframe/v1",
                appKey: endpointId || legacyIframeKey,
                featureTargets: subTab.featureId ? featuresById[subTab.featureId]?.targets : undefined,
            }
        }
    });

    return <>
        {!isWizardOpen && <TabHeader logo={currentService.logo} label={currentService.name}>
            <TabLinks>
                {subTabs.map(({ to, label }) => {
                    return <NavLink key={label} to={`${currentService.service.to}${to}`}>{label}</NavLink>
                })}
            </TabLinks>
        </TabHeader>}
        <Switch>
            {subTabs.map(({ to, label, legacySubService, appKey, featureTargets, version, scope, isMfeApp}) => {
                return <Route path={`${currentService.service.to}${to}`} key={label}>
                            <DefaultRoute
                                featureTargets={featureTargets}
                                subService={legacySubService}
                                needHeader={false}
                            >
                               {
                                   isMfeApp && !_.isEmpty(mfeServices) ? (
                                        <RenderMfe 
                                        scope={scope}
                                        setWizardOpen={setWizardOpen} />
                                    ) : (
                                        <RenderIframe
                                        subTabLabel={label}
                                        version={version}
                                        iframeKey={appKey}
                                        currentService={currentService}
                                        isThirdParty={false}
                                        isWizardOpen={isWizardOpen}
                                        setWizardOpen={setWizardOpen}
                                        />
                                    )
                                }

                            </DefaultRoute>
                </Route>
            })}
            <Redirect to={`${currentService.service.to}${subTabs[0].to}`}/>
        </Switch>
    </>
})

export default React.memo(() => {
    const history = useHistory();
    const location = useLocation();
    const currentService = useCurrentService();
    const match = useRouteMatch(`${currentService.service.to}/:id`)
    const isThirdParty = currentService.service.isThirdParty;
    const dispatch = useDispatch();
    const selectedAgentId = useSelector(state => state.tenancy.selectedAgentId);
    const openAddConnector = currentService.service.needsConnector && !selectedAgentId;
    const saasEnabled = useSelector(state => state.tenancy.selectedAccount.isSaas)
    const doRedirect = useSelector(state => state.features.active["NetApp.SystemManager/sysmgrCredentials"])
    const forceUserCred = useSelector(state => state.currentConnector?.sysMgrUserCred)
    const { thirdPartyToken } = useSelector(state => state.auth);

    const getSubTabPathName = () => {
        let pathname= window.location.pathname;
        pathname = (pathname.endsWith('/') ? pathname.slice(0, -1) : pathname)
        return  pathname.substring(pathname.lastIndexOf('/'), pathname.length);
    }

    useEffect(() => {
        if (isThirdParty) {
            dispatch({
                type: "AUTH:THIRD-PARTY-AUDIENCE"
            })
        }
    }, [isThirdParty, dispatch])

    useEffect(() => {
        const isValidated = location.state?.isValidated; 
        const subTabPathName =  getSubTabPathName().replace("/","");
        if(!isValidated && doRedirect && currentService.service.redirectURL && match?.params?.id && forceUserCred){
            const locationParams = {
                pathname: `${currentService.service.redirectURL.replace(":weId",subTabPathName)}`,
                state: {...location.state, redirectPath: location.pathname}
            }
            history.push(locationParams)
        }
    }, [currentService, forceUserCred])

    const subTabServicesByTo = _.keyBy(currentService?.uiIntegration?.subTabs, "to");

    const subTabPathName = getSubTabPathName()

    if (openAddConnector && (saasEnabled || process.env.REACT_APP_IS_SAAS !== "true")) {
        return <Redirect to={{ pathname: window.location.pathname, hash: "#addConnector" }}/>;
    } else if (isThirdParty && !thirdPartyToken) {
        return <MainLoader/>
    } else if (currentService.service.isParent) {
        return <ParentService key={currentService.key} currentService={currentService}/>
    } else {
        // If no parent then get the 0 Index
        const { endpointId, scope } = _.get(currentService, 'uiIntegration.stageIntegrations[0]') || {};
        return <DefaultRoute>
                {externals.serviceMfes[endpointId] ? (
                <RenderMfe scope={scope} />
                ) : (
                <RenderIframe
                    key={currentService.key}
                    currentService={currentService}
                    isThirdParty={isThirdParty}
                    featureId={subTabServicesByTo[subTabPathName]?.featureId}
                />
                )}
            </DefaultRoute>;
    }
})
