import React, { Suspense } from "react";
import _ from "lodash";
import {
  MainLoader,
  Notification,
  Text,
} from "@netapp/bxp-design-system-react";
import externals from "utils/externals";
import useModuleFederation from "utils/mfe/useModuleFederation";
import useDynamicImport from "utils/mfe/useDynamicImport";
import MainErrorBoundary from "../MainErrorBoundary";

const notificationStyle = {
  width: "900px",
  position: "absolute",
  bottom: "67px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: "999999",
};

const RenderMfe = ({
  module = "module",
  scope,
  setWizardOpen,
}) => {
  const [Component, notification] = useDynamicImport({ module, scope });
  const { initializationData } = useModuleFederation({scope, setWizardOpen});
  
  return (
    <MainErrorBoundary>
      <Suspense fallback={<MainLoader />}>
        {Component && <Component {...initializationData} />}

        {notification && (
          <Notification type="error" style={notificationStyle}>
            <Text level={13}>{notification}</Text>
          </Notification>
        )}
      </Suspense>
    </MainErrorBoundary>
  );
};

export default RenderMfe;
