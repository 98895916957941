import {setItem} from "utils/localstorage";

const defaultState = () => ({
    updateVersions: null,
    about: null,
    supportServices: null,
    sysMgrUserCred: false
});

export default  (state = defaultState(), {type, payload}) => {
    switch (type) {
        case "CURRENT-CONNECTOR:CLOSE-WEAK-CONNECTOR-BANNER": {
            return {...state, hideWeakConnectorBanner: true}
        }
        case "CURRENT-CONNECTOR:OPEN-WEAK-CONNECTOR-DIALOG": {
            return {...state, isWeakConnectorDialogOpen: true, weakConnectorDialogProvider: payload?.provider}
        }
        case "CURRENT-CONNECTOR:CLOSE-WEAK-CONNECTOR-DIALOG": {
            return {...state, isWeakConnectorDialogOpen: false, weakConnectorDialogProvider: null}
        }
        case "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE-PENDING": {
            return {...state, updateVersions: null}
        }
        case "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE-SUCCESS": {
            const {updateVersions, autoUpgrade, sysMgrUserCred} = payload;
            return {...state, updateVersions, autoUpgrade, sysMgrUserCred}
        }
        case "CURRENT-CONNECTOR:FETCH-CONFIG-PENDING": {
            return {...state, config: null}
        }
        case "CURRENT-CONNECTOR:FETCH-CONFIG-SUCCESS": {
            const {config} = payload;
            return {...state, config}
        }
        case "CURRENT-CONNECTOR:HIDE-UPDATES-AVAILABLE": {
            if (payload?.permanent) {
                setItem({itemKey: `hide-update.${payload.permanent}`}, true)
            }

            return {...state, hideUpdatesAvailable: true}
        }

        case "CURRENT-CONNECTOR:FETCH-ABOUT-PENDING": {
            return {...state, isLoadingAbout: true}
        }
        case "CURRENT-CONNECTOR:FETCH-ABOUT-SUCCESS": {
            return {...state, isLoadingAbout: false, about: payload.data}
        }
        case "CURRENT-CONNECTOR:FETCH-ABOUT-ERROR": {
            return {...state, isLoadingAbout: false}
        }

        case "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT-PENDING": {
            return {...state, isLoadingInstancePlacement: true}
        }
        case "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT-SUCCESS": {
            return {...state, isLoadingInstancePlacement: false, instancePlacement: payload.data || { installLocation: "on-prem"}}
        }
        case "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT-ERROR": {
            return {...state, isLoadingInstancePlacement: false}
        }


        case "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES-PENDING": {
            return {...state, isLoadingSupportServices: true}
        }
        case "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES-SUCCESS": {
            return {...state, isLoadingSupportServices: false, supportServices: payload.data}
        }
        case "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES-ERROR": {
            return {...state, isLoadingSupportServices: false}
        }
        case "CURRENT-CONNECTOR:UPDATE-SYSMG": {
            return {...state, sysMgrUserCred: payload?.sysMgrUserCred}
        }
        default: {    
            return state;
        }
    }
}
