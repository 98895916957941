import {apiClient, occmClient, externalClient} from "./clients";
import _ from "lodash";
import {resolve} from "./async";

export const clientsMapper = {
    occm: occmClient,
    api: apiClient,
    external: externalClient
};

export const generateApiKey = requestProps => {
    const {client, method, url, requestId} = requestProps;
    const baseUrl = client ? clientsMapper[client]?.defaults?.baseURL : "";
    return requestId ? `${method.toUpperCase()}:${baseUrl}${url}:${requestId}` : `${method.toUpperCase()}:${baseUrl}${url}`;
};

export const requestApi = ({
                               uniqueId,
                               actionType,
                               sendCompleteResponse = false,
                               client,
                               clientOpts = {},
                               method,
                               url,
                               onSuccess: _onSuccess = [],
                               onFailure: _onFailure = [],
                               mapResponseToPayload = value => value,
                               mapErrorToPayload = error => error}) => async store =>{
    const {dispatch} = store;
    let onFailure = _.isArray(_onFailure) ? _onFailure : [_onFailure];
    let onSuccess = _.isArray(_onSuccess) ? _onSuccess : [_onSuccess];

    dispatch({type: `${actionType}-PENDING`, meta: {key: actionType, uniqueId}});

    const fetch = clientsMapper[client];

    const {value, error} = await resolve(fetch({method, url, ...clientOpts}));
    if (value) {
        // dispatch onSuccess and onFailure callbacks before dispatching API status
        // so rendering of components based on API status will occur after API response/error are in store
        onSuccess.map(callback => dispatch(callback(sendCompleteResponse ? value : value?.data)));
        // the below code will always dispatch ACTION-NAME-SUCCESS. you can handle the action in any specific reducer
        dispatch({
            type: `${actionType}-SUCCESS`,
            meta: {key: actionType, uniqueId},
            payload: mapResponseToPayload(sendCompleteResponse ? value : value?.data)
        });
    } else {
        onFailure.map(callback => dispatch(callback(error)));
        // the below code will always dispatch ACTION-NAME-SUCCESS. you can handle the action in any specific reducer
        dispatch({
            type: `${actionType}-FAILURE`,
            meta: {key: actionType, uniqueId},
            payload: mapErrorToPayload(error)
        });
    }
};

/**
 Extracts error from given error object
 Handles:
    - errors from axios (have e.response.data)
        => error can the the response payload, message property of response payload, or nested payload.error.message
    - error which is Error/object that has message property
    - error which is only a string
 * @returns {*|string}
 */
    export const extractApiFailureMessage = (e, errMsgKey) => {
        const data = e?.response?.data;
        if (_.isString(data)) {
            return data;
        } else if (_.isObject(data)) {
            if (_.isString(data.message)) {
                return data.message
            } else if (_.isArray(data.message) && !_.isEmpty(data.message) && _.isString(data.message[0])) {
                return data.message.join(", ");
            }
            else if (_.isString(data?.error)) {
                return data.error;
            } else if (data && _.isString(data[errMsgKey])) {
                return data[errMsgKey];
            } else {
                return data?.error?.message || "Unknown";
            }
    
        } else if (_.isString(e)) {
            return e;
        } else {
            return e.message;
        }
    };
