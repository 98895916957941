import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "./ServiceIframe.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {useHistory, useLocation} from 'react-router';
import _ from "lodash";
import store from "store";
import { BarLoader } from "@netapp/bxp-design-system-react";
import {Redirect} from "react-router-dom";
import externals from "utils/externals";


const iframeRef = React.createRef();
const readyRef = React.createRef(false);
export const pushNgMessage = ({type, payload}) => {
    const cvoAngularUrl = externals.serviceIframes['cvo-angular'];
    const iframeOrigin = cvoAngularUrl && new URL(cvoAngularUrl, cvoAngularUrl.startsWith("http") ? undefined : window.location.origin).origin;
    
    if(!readyRef.current) {
        console.log("Attempting to push message before ready");
    }
    if(iframeRef.current && readyRef.current) {
        iframeRef.current.contentWindow.postMessage({
            type, payload
        }, iframeOrigin);
    }
};

const mapAngularRouteToReactRoute = {
    'cvs-tab-iframe': '/cloud-volumes-service',
    'wizard': '/add-new-working-environment-wizard',
    'main.working-environments.monitoring.index': '/cvo-resources',
    'main.working-environments.monitoring.replication-status': '/cvo-resources/replications',
    'main.working-environments.index': '/working-environments',
    'main': '/working-environments',
    'cloud-ontap-upgrade': '/upgrade-ontap-wizard',
    'mediator-upgrade': '/upgrade-mediator-wizard',
    'add-provider-credentials-wizard-new': '/marketplace-subscription',
    'snap-mirror-wizard': '/snap-mirror-wizard',
    'create-new-volume-wizard': '/create-new-volume-wizard',
    'main.working-environments.monitoring.cost': '/cvo-resources/cost',
    'main.snapCenter': '/snapcenter',
    'main.kubernetes': '/kubernetes',
    'main.gfc': '/gfc'
};

const ApiResponseListener = React.memo(({iframeOrigin}) => {
    const apiResponses = useSelector(state => state.occmProxy?.responses);
    const dispatch = useDispatch();

    useEffect(() => {
        if( apiResponses.length > 0 ) {
            const messageGroups = _.groupBy(apiResponses, "type");
            if(messageGroups["NSS-ADDED"]?.length > 0) {
                pushNgMessage({
                    type: "NSS-ADDED"
                }, iframeOrigin)
            }

            dispatch({
                type: 'OCCM-PROXY:RESPONSES-HANDLED', payload: apiResponses
            });
        }

    }, [ apiResponses, dispatch]);

    return "";
})

const now = new Date().valueOf();
export default React.memo(() => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {idTokenPayload, accessToken, isAuthenticated} = useSelector(state => state.auth);
    const { selectedAccountId, selectedWorkspace, selectedWorkspaceId, selectedAgentId, agentStatus } = useSelector(state => state.tenancy);

    const history = useHistory();
    const {supportServices, ngReady, killNg, loadNgWrapper} = useSelector(state => state.app);
    const [isInitialized, setInitialized] = useState(false);
    const isShowing = useSelector(state => state.app.showNgWrapper);
    const isFullPane = useSelector(state => state.app.ngWrapperFullPane);
    const isReady = useSelector(state => state.app.ngReady);
    const isShowingRef = useRef(null);
    const {pathname, state} = location;

    const cvoAngularUrl = externals.serviceIframes['cvo-angular'];
    const iframeOrigin = cvoAngularUrl && new URL(cvoAngularUrl, cvoAngularUrl.startsWith("http") ? undefined : window.location.origin).origin;

    isShowingRef.current = isShowing;

    useEffect(() => {
        const receiveNgMessage = (event) => {

            if (!iframeRef.current || event.origin !== iframeOrigin || event.source !== iframeRef.current.contentWindow) {
                return;
            }

            if (event.data.type === "Start-Poll") {
                const _taskId = _.get(event, 'data.payload.taskId');
                if (_taskId) {
                    dispatch({
                        type: 'POLLER:INITIATE-POLL', payload: {taskId: _taskId}
                    });
                }

            }

            if (event.data.type === "READY") {
                readyRef.current = true;
                dispatch({type: "APP:ANGULAR-READY"});
            } else if (event.data.type === "UPDATE-ROUTE" && isShowingRef.current) {
                const angularRoute = _.get(event.data, 'payload.location');
                history.push(mapAngularRouteToReactRoute[angularRoute]);
            } else if (event.data.type === "ADD-CONNECTOR") {
                history.replace({
                    ...history.location,
                    hash: "#addConnector"
                });
            } else if (event.data.type === "RELOAD") {
                window.location.reload();
            } else if (event.data.type === "SERVICE:NAVIGATE") {
                let selection = undefined;
                const payload = event.data.payload;
                if(payload.selection && payload.selection.workingEnvironments && _.isArray(payload.selection.workingEnvironments)) {
                    selection = {
                        workingEnvironments: payload.selection.workingEnvironments
                    }
                } else if (payload.selection && payload.selection.workingEnvironment && _.isObject(payload.selection.workingEnvironment)) {
                    selection = {
                        workingEnvironment: payload.selection.workingEnvironment
                    }
                }

                history.push(payload.pathname, {
                    ...payload.state,
                    service: payload.service === "right-panel" || "main-app",
                    action: payload.action,
                    selection
                })
            }else if(event.data.type === "SERVICE:ADD-NSS"){
                dispatch({
                    type: "SUPPORT:ADD-NSS-OPEN-POPOVER"
                })
            }
        };

        window.addEventListener("message", receiveNgMessage, false);

        return () => {
            window.removeEventListener("message", receiveNgMessage)
        }
    }, [dispatch]);

    useEffect(() => {
        if(killNg) {
            setInitialized(false);
        }
    }, [killNg]);

    useEffect(() => {
        if (isInitialized) {
            pushNgMessage({
                type: "WORKSPACE-CHANGE",
                payload: {workspace: selectedWorkspace, location: window.location.pathname}
            });
        }
    }, [selectedWorkspace]);

    useEffect(() => {
        if (isInitialized) {
            pushNgMessage({
                type: "AGENT-CHANGE",
                payload: {agentId: selectedAgentId, location: window.location.pathname}
            });
        }
    }, [selectedAgentId]);

    useEffect(() => {
        const payload = {
            location: pathname,
            params: state
        };

        pushNgMessage({
            type: "ROUTE-CHANGE",
            payload: payload
        });
    }, [dispatch, pathname, state]);

    useEffect(() => {
        const workingEnvironments = _.get(store.getState(), "gallery.workingEnvironments", []);
        if (ngReady) {
            if (process.env.REACT_APP_IS_SAAS === "true" && agentStatus !== "PENDING" && selectedWorkspace && !isInitialized) {
                pushNgMessage({
                    type: "NG-INITIALIZE",
                    payload: {
                        userMetadata: idTokenPayload,
                        accessToken,
                        accountId: selectedAccountId,
                        agentId: selectedAgentId,
                        workspaceId: selectedWorkspaceId,
                        workspace: selectedWorkspace,
                        location,
                        workingEnvironments: JSON.stringify(workingEnvironments)
                    }
                });
                setInitialized(true);
            } else if (process.env.REACT_APP_IS_SAAS !== "true" && selectedWorkspace && !isInitialized) {
                pushNgMessage({
                    type: "NG-INITIALIZE",
                    payload: {
                        userMetadata: idTokenPayload,
                        accessToken,
                        workspaceId: selectedWorkspaceId,
                        workspace: selectedWorkspace,
                        supportServices,
                        location,
                        workingEnvironments: JSON.stringify(workingEnvironments),
                        isLocalDark: process.env.REACT_APP_IS_DARK === 'true',
                        tenancyAccountId: selectedAccountId
                    }
                });
                setInitialized(true);
            }
        }

    }, [agentStatus, ngReady, selectedWorkspace]);

    return <>
        {location?.pathname?.startsWith("/cvo-resources") && !location?.state?.workingEnvironmentId && !isInitialized && <Redirect to={{pathname: "/working-environments", hash: window.location.hash}}/>}
        {isShowing && !isReady && <BarLoader className={styles['main-bar']}/>}
        {isShowing && <ApiResponseListener iframeOrigin={iframeOrigin}/>}
        {!killNg && isAuthenticated && loadNgWrapper && <iframe title="app" ref={iframeRef} className={classNames(styles['base'], {[styles['full-pane']]: isFullPane})} src={`${cvoAngularUrl}?r=${now}`} style={{visibility: isShowing ? "visible" : "hidden"}}/>}
    </>
});
