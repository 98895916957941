import features from "features";
import { getItem, setItem, removeItem, removeItemAnon, setItemAnon, getItemAnon } from "utils/localstorage";
import _ from "lodash";

const featuresMap = _.keyBy(features, "key");

const alwaysEnabledFeatures = (process.env.REACT_APP_FEATURES || "").split(";");
export default (state = {active: {}, list: [], servicesList: []}, {payload, type}) => {
    switch (type){
        case "FEATURES:INITIALIZE": {
            const { services, supportServices } = payload;

            const active = {};
            const byKey = {};

            const servicesList = _.chain(services).filter(service => service.service).map(service => {
                if(service.service.subServices) {
                    return service.service.subServices.map(subService => ({
                        name: `${service.name} - ${subService.label}`,
                        key: `${service.key}.${subService.key}`,
                        parentKey: service.key,
                        service: subService
                    })).concat(service);
                } else {
                    return service;
                }
            }).flatten().map(service => {
                const feature = {title: `Enable service: ${service.name}`, key: service.key}
                if(process.env.REACT_APP_IS_SAAS !== "true") {
                    const localKey = _.get(service, "service.localKey");
                    const supportServiceKey = _.get(service, "service.supportServiceKey")

                    if(!localKey && !supportServiceKey) {
                        active[feature.key] = true;
                        if(service.parentKey) {
                            active[service.parentKey] = true;
                        }
                        return {...feature, active: true, allowToggle: false}
                    }

                    const isSupported = _.get(supportServices, localKey, false) || _.get(supportServices, ["servicesInformation", supportServiceKey, "enabled"], false);
                    const isFlagEnabled = getItem({itemKey: `feature.${service.key}`});
                    const status = isSupported || isFlagEnabled;

                    if(status) {
                        active[feature.key] = true;
                        if(service.parentKey) {
                            active[service.parentKey] = true;
                        }
                    }

                    return {...feature, active: status, alwaysOn: isSupported, allowToggle: true};
                } else {
                    const needsFlag = _.get(service, "service.needsFlag", false);
                    const key = _.get(service, "key");

                    if(!needsFlag) {
                        active[feature.key] = true;
                        if(service.parentKey) {
                            active[service.parentKey] = true;
                        }
                        return {...feature, active: true, allowToggle: false}
                    }
                    const status = alwaysEnabledFeatures.includes(key) || getItem({itemKey: `feature.${key}`});

                    if(status) {
                        active[feature.key] = true
                        if(service.parentKey) {
                            active[service.parentKey] = true;
                        }
                    }

                    return {...feature, active: status, alwaysOn: alwaysEnabledFeatures.includes(feature.key), allowToggle: true}
                }
            }).value();

            const oldStyleFeaturesList = features.map(feature => {
                const { key } = feature;
                let status = alwaysEnabledFeatures.includes(key);

                if(!status) {
                    if(featuresMap[key].anon) {
                        status = getItemAnon({itemKey: `feature.${key}`});
                    } else {
                        status = getItem({itemKey: `feature.${key}`});
                    }
                }

                if(status) {
                    active[key] = true;
                }

                return {...feature, active: status, alwaysOn: alwaysEnabledFeatures.includes(key)}
            });

            const serviceIntegrationFeatureList = [];
            const newStyleFeaturesList = _.chain(services).filter(service => service.uiIntegration?.features).map(service => service.uiIntegration.features).flatten().map(feature => {
                const { id, title } = feature;
                let status = false;
                let alwaysOn = false;

                if(process.env.REACT_APP_IS_SAAS === "false") {
                    if(feature.targets.supportServiceKey) {
                        const isSupported =  _.get(supportServices, ["servicesInformation", feature.targets.supportServiceKey, "enabled"], false);
                        if(isSupported) {
                            status = true;
                            alwaysOn = true;
                        }
                    } else if (process.env.REACT_APP_IS_DARK !== "true") {
                        if(feature.targets.isSaas && !feature.targets.noLocal) {
                            //targets.isSaas refers to account saas status, not ui deploy mode
                            status = true;
                            alwaysOn = true;
                        }
                    }
                }

                if(process.env.REACT_APP_IS_SAAS === "true" && feature.targets.isSaas) {
                    status = true;
                    alwaysOn = true;
                }

                if(process.env.REACT_APP_IS_DARK === "true" && feature.targets.isDark) {
                    status = true;
                    alwaysOn = true;
                }

                if(!status) {
                    if(feature.isAnon) {
                        status = getItemAnon({itemKey: `feature.${id}`});
                    } else {
                        status = getItem({itemKey: `feature.${id}`});
                    }
                }

                if(status) {
                    active[id] = true;
                }

                byKey[id] = { title, active: status, alwaysOn, key: id, targets: feature.targets, isAnon: feature.isAnon }
                if (!_.isUndefined(feature.targets.isServiceIntegration)) {
                    const key = `${id}/serviceIntegration`;
                    const serIntTitle = `${title} - Service Integration`;
                    let status = feature.targets.isServiceIntegration;
                    if(feature.isAnon) {
                        status = getItemAnon({itemKey: `feature.${key}`});
                    } else {
                        status = getItem({itemKey: `feature.${key}`});
                    }
                    if (status || feature.targets.isServiceIntegration) {
                        active[key] = true;
                    }
                    byKey[key] = { title: serIntTitle, active: status, alwaysOn: feature.targets.isServiceIntegration, key, parentkey: id, targets: feature.targets, isAnon: feature.isAnon }
                    serviceIntegrationFeatureList.push({ title: serIntTitle, active: status, alwaysOn: feature.targets.isServiceIntegration, key })
                }
                
                return { title, active: status, alwaysOn, key: id }
            }).value();

            return {active, list: oldStyleFeaturesList.concat(newStyleFeaturesList).concat(serviceIntegrationFeatureList), servicesList, isInitialized: true, byKey};
        }
        case "FEATURES:TOGGLE-FEATURE": {
            const {feature} = payload;
            const isActive = state.active[feature];
            const itemKey = `feature.${feature}`;
            const featuresByKey = state.byKey;

            if(isActive){
                if(featuresMap[feature]?.anon || featuresByKey[feature]?.isAnon) {
                    removeItemAnon({itemKey});
                } else {
                    removeItem({itemKey});
                }
            } else {
                if(featuresMap[feature]?.anon || featuresByKey[feature]?.isAnon) {
                    setItemAnon({itemKey}, true);
                } else {
                    setItem({itemKey}, true)
                }
            }

            window.location.reload();
            return state;
        }
        default :{
            return state;
        }
    }
};
